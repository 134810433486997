.basic {
    h3 {
        font: 500 3rem "PF Din Text Comp Pro Medium", sans-serif;
        text-transform: uppercase;
        margin: 5rem 0 2rem;
    }
    
    h4 {
        font: 600 1.4rem "Inter", sans-serif;
        color: #212121;
        line-height: 1.42;
        text-transform: none;
    }
    
    p {
        font-weight: 400;
        margin-bottom: 0.5rem;
        a {text-decoration: underline;}
    }
    
    ul {
        li + li {margin-top: 0.5rem;}
    }
    
    .scrolled-wrapper {
        position: relative;
        overflow: hidden;
        cursor: move;
        margin: 3.6rem 0;
    }
    
    .scrolled-wrapper .scrolled-wrapper_scrolled {
        outline: 0.2rem solid #eceff7;
    }
    
    .scrolled_h {
        max-width: 100%;
        overflow:hidden;
        cursor: move;
    }
    
    .scrolled_h > table {
        max-width: 9999em;
    }
    
    .scrolled_h__top-scroll {
        position: absolute;
        width: 100%;
        height: 2rem;
        overflow-x: auto;
        display: none;
    }
    
    .fake-scroll {
        height: 0.1rem;
    }
    
    table {
        font-size: 1.2rem;
        font-weight: 400;
        max-width: max-content;
        width: 100%;
        border-collapse: collapse;
        display: block;
        @media (max-width: 768px) {
            display: block;
            max-width: -moz-fit-content;
            max-width: fit-content;
            // overflow-x: auto;
            // white-space: nowrap;
            width: 0;
            min-width: 100%;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            cursor: move;
        }
    }
    
    .table caption {
        padding: 1.25rem 1.75rem;
        background: #9496b0;
        color: #fff;
        font-size: 2.4rem;
        text-align: left;
    }
    
    table td {
        border: 0.1rem solid #e4e7eb;
        padding: 1rem;
        background: rgba(236, 239, 247, .3);
    }
    
    table tbody th,
    table thead th {
        padding: 1.4rem 2rem;
        background: #e4e7eb;
        font-weight: 400;
    }
    
    
    
    .toggle-table__inner{
        display: none;
    }
    
    .toggle-table_open {
        padding-top: 2rem;
    }
    .toggle-table__caption {
        position: relative;
        z-index: 1;
        cursor: pointer;
        padding: 1.25rem 1.75rem;
        padding-right: 3rem;
        background: #eceff7 url('../images/zoom-in.svg') no-repeat 99.25% center;
        background-size: 2rem 2rem;
        font-size: 1.8rem;
        line-height: 1.42;
        font-weight: 400;
        color: #242338;
    }
    .toggle-table__caption:hover {
        color: #2774ea;
    }
    
    .toggle-table_open .toggle-table__caption {
        position: static;
        background: none;
        text-align: center;
        height: 3rem;
    }
    
    .toggle-table_open .toggle-table__caption-title{
        display: none;
    }
    
    .toggle-table__caption-close {
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        height: 3rem;
        padding: 0.5rem;
        background: #eceff7;
        display: none;
    }
    .toggle-table_open .toggle-table__caption-close {
        display: block;
    }
    .toggle-table_open .toggle-table__caption-close:after {
        content: url('/addons/default/themes/sibwaypro_old/images/close-menu.svg');
        vertical-align: top;
        display: inline-block;
        margin-left: 1rem;
    }
    
    .toggle-table table {
        table-layout: fixed;
    }
    
    
    .scrolled-wrapper_toggle-table_open .scrolled_h__top-scroll {
        height: 5rem;
    }
    
}
